import $ from "jquery";
global.$ = $;
require("jquery-validation");


$.validator.addMethod("email", function(value, element) {
    return this.optional(element) || /^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+\.[a-zA-Z.]{2,5}$/i.test(value);
}, "Adresse e-mail non valide: Veuillez entrer une adresse e-mail valide.");
$.validator.addMethod("telephone", function(value, element) {
    return this.optional(element) || /^[+]{0,1}[0-9]{10,14}$/i.test(value);
}, "Format invalide. (Exemple: 0611111111)");

$(document).ready(function() {

    $("#form_condidature_register").validate({
        errorElement: "samp",
        rules: {
            "candidature[nom]": {
                required: true,
                minlength: 3
            },
            "candidature[prenom]": {
                required: true,
                minlength: 3
            },
            "candidature[adresse]": {
                required: true,
                minlength: 3
            },
            "candidature[email]": {
                required: true,
                email: true
            },
            "candidature[telephone]": {
                required: true,
                phone: true
            },
            "candidature[curriculumVitae]": {
                required: true
            },
            "candidature[lettreMotivation]": {
                required: true
            }
        },

        messages: {
            "candidature[nom]": {
                required: "Veuillez saisir votre nom complet",
                minlength: "Votre nom pas correct"
            },
            "candidature[prenom]": {
                required: "Veuillez saisir votre nom complet",
                minlength: "Votre prenom pas correct"
            },
            "candidature[adresse]": {
                required: "Veuillez saisir votre nom complet",
                minlength: "Votre prenom pas correct"
            },
            "candidature[email]": {
                required: "Veuillez saisir votre email",
                email: "Votre email pas correct"
            },

            "candidature[telephone]": {
                required: "Veuillez saisir votre Téléphone",
                phone: "Votre Téléphone pas correct"
            },

            "candidature[curriculumVitae]": {
                required: "Veuillez selecte votre CV",
            },

            "candidature[lettreMotivation]": {
                required: "Veuillez selecte votre Lettre de Motivation",
            }

        }

    });

});