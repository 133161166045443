import 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '../css/app.scss';
import '../bootstrap';
import 'owl.carousel';

global.$ = global.jQuery = $;

import './_formlead.js';
import './_formcandidature.js';
import './homepage.js';
import '../js/frontend/_home';