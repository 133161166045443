//import $ from 'jquery';

import "jquery";

import WOW from "wow.js";

import owlcarousel from "owl.carousel";

import "magnific-popup";

import magnificPopup from "magnific-popup";

$.validator.addMethod(
    "email",

    function (value, element) {
        return this.optional(element) || /^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+\.[a-zA-Z.]{2,5}$/i.test(value);
    },

    "Adresse e-mail non valide: Veuillez entrer une adresse e-mail valide."
);

$.validator.addMethod(
    "telephone",

    function (value, element) {
        return this.optional(element) || /^[+]{0,1}[0-9]{10,14}$/i.test(value);
    },

    "Format invalide. (Exemple: 0611111111)"
);

$(document).ready(function () {
    $("#form_condidature_register").validate({
        errorElement: "samp",

        rules: {
            "candidature[nom]": {
                required: true,

                minlength: 3,
            },

            "candidature[prenom]": {
                required: true,

                minlength: 3,
            },

            "candidature[adresse]": {
                required: true,

                minlength: 3,
            },

            "candidature[email]": {
                required: true,

                email: true,
            },

            "candidature[telephone]": {
                required: true,

                phone: true,
            },

            "candidature[curriculumVitae]": {
                required: true,
            },

            "candidature[lettreMotivation]": {
                required: true,
            },
        },

        messages: {
            "candidature[nom]": {
                required: "Veuillez saisir votre nom complet",

                minlength: "Votre nom pas correct",
            },

            "candidature[prenom]": {
                required: "Veuillez saisir votre prenom complet",

                minlength: "Votre prenom pas correct",
            },

            "candidature[adresse]": {
                required: "Veuillez saisir votre adresse",

                minlength: "Votre prenom pas correct",
            },

            "candidature[email]": {
                required: "Veuillez saisir votre email",

                email: "Votre email pas correct",
            },

            "candidature[telephone]": {
                required: "Veuillez saisir votre Téléphone",

                phone: "Votre Téléphone pas correct",
            },

            "candidature[curriculumVitae]": {
                required: "Veuillez selecte votre CV",
            },

            "candidature[lettreMotivation]": {
                required: "Veuillez selecte votre Lettre de Motivation",
            },
        },
    });
});

$(document).ready(function () {
    $("#hamburger").on("click", function (e) {
        e.preventDefault();
        $(".hamburger").toggleClass("animate");
        $(".bar").toggleClass("animate");
        $(".menu_global").toggleClass("hide_menu show_menu");
        $("body").toggleClass("animate");
    });

    $("#je_suis_interesse_btn").on("click", function (e) {
        e.preventDefault();

        $(".content_contact_projet").toggleClass("hide show");
    });

    $("#visite_virtuelle").on("click", function (e) {
        e.preventDefault();
        $(".visite_virtuelle").toggleClass("hide show");
    });

    $("#acces_rapide_button_forms").click(function () {
        $(".content_contact_projet").toggleClass("hide show");
    });

    $("#etre_contacte_form_contact_click").click(function () {
        $(".content_contact_projet").toggleClass("hide show");
    });

    $(".contact_location_info").on("click", function (e) {
        e.preventDefault();

        $(".contact_location_info").removeClass("active");

        $(this).addClass("active");
    });

    $(".drop_down_a").on("click", function (e) {
        e.preventDefault();

        $(".drop_down").toggleClass("hide show");
    });

    const projetsnextIcon = '<img src="/assets/images/slider_nav_icon.png">';

    $(window).bind("load resize", function () {
        const projetsnextIcon = '<img src="/assets/images/slider_nav_icon.png">';

        if ($(this).width() < 991) {
            $("#home_programmes_slider").addClass("owl-carousel");

            $("#home_programmes_slider").addClass("owl-theme");

            var owl = $("#home_programmes_slider");

            owl.owlCarousel({
                items: 1,

                loop: false,

                margin: 0,

                dots: false,

                nav: true,

                autoplay: false,

                responsiveClass: true,

                navText: [projetsnextIcon, projetsnextIcon],
            });
        } else {
            $("#home_programmes_slider").removeClass("owl-carousel");

            $("#home_programmes_slider").removeClass("owl-theme");

            $("#home_programmes_slider").trigger("destroy.owl.carousel");
        }
    });

    const actunextIcon = '<img src="/assets/images/right_arrow_actu_slider.png">';

    var owl = $("#home_actu_slider");

    owl.owlCarousel({
        items: 3,

        loop: false,

        dots: false,

        nav: true,

        margin: 10,

        responsiveClass: true,

        autoplayTimeout: 8000,

        autoplayHoverPause: true,

        navText: [actunextIcon, actunextIcon],

        responsive: {
            0: {
                stagePadding: 20,

                items: 1,
            },

            567: {
                stagePadding: 100,

                items: 1,
            },

            767: {
                stagePadding: 100,

                items: 1,
            },

            992: {
                items: 3,
            },

            1200: {
                items: 3,
            },
        },
    });

    const partenairesnextIcon = '<img src="/assets/images/arrow_partenaires_slider.png">';

    var owl = $("#partenaires_home_slider");

    owl.owlCarousel({
        items: 4,

        loop: true,

        dots: false,

        nav: true,

        responsiveClass: true,

        autoplayTimeout: 8000,

        autoplayHoverPause: true,

        navText: [partenairesnextIcon, partenairesnextIcon],

        responsive: {
            0: {
                items: 1,
            },

            567: {
                items: 2,
            },

            992: {
                items: 3,
            },

            1200: {
                items: 4,
            },
        },
    });

    const gallery_projetnextIcon = '<img src="/assets/images/gallery_projet_images_slider_arrow.png">';

    $("#gallery_projet_images_slider").owlCarousel({
        items: 1,

        loop: true,

        dots: false,

        nav: true,

        stagePadding: 400,

        margin: 10,

        responsiveClass: true,

        autoplayTimeout: 8000,

        autoplayHoverPause: true,

        navText: [gallery_projetnextIcon, gallery_projetnextIcon],

        responsive: {
            0: {
                stagePadding: 50,
            },

            567: {
                stagePadding: 150,
            },

            992: {
                stagePadding: 250,
            },

            1200: {
                stagePadding: 300,
            },

            1400: {
                stagePadding: 400,
            },
        },
    });

    const gallery_vermarinenextIcon = '<img src="/assets/images/arrow_vert-marine.png">';

    $("#gallery_vermarine_slider_images_slider").owlCarousel({
        items: 1,

        loop: true,

        dots: false,

        nav: true,

        stagePadding: 400,

        margin: 10,

        responsiveClass: true,

        autoplayTimeout: 8000,

        autoplayHoverPause: true,

        navText: [gallery_vermarinenextIcon, gallery_vermarinenextIcon],

        responsive: {
            0: {
                stagePadding: 0,
            },

            567: {
                stagePadding: 0,
            },

            992: {
                stagePadding: 250,
            },

            1200: {
                stagePadding: 300,
            },

            1400: {
                stagePadding: 400,
            },
        },
    });

    var projet_id = $(".class_to_get_name_of").attr("id");

    var gallery_nextIcon = '<img src="/assets/images/arrow_' + projet_id + '.png">';

    $("#almaz_developpeur_projet_images_slider").owlCarousel({
        items: 2,

        loop: false,

        dots: false,

        nav: true,

        margin: 20,

        responsiveClass: true,

        stagePadding: 100,

        autoplayTimeout: 8000,

        autoplayHoverPause: true,

        navText: [gallery_nextIcon, gallery_nextIcon],

        responsive: {
            0: {
                items: 1,

                stagePadding: 0,
            },

            567: {
                items: 1,

                stagePadding: 0,
            },

            767: {
                items: 1,

                stagePadding: 75,
            },

            992: {
                items: 2,

                stagePadding: 75,
            },

            1200: {
                items: 2,

                stagePadding: 100,
            },
        },
    });

    $(".almaz_developpeur_projet_images_slider").each(function () {
        $(this).owlCarousel({
            items: 2,

            loop: false,

            dots: false,

            nav: true,

            margin: 20,

            responsiveClass: true,

            stagePadding: 100,

            autoplayTimeout: 8000,

            autoplayHoverPause: true,

            navText: [gallery_nextIcon, gallery_nextIcon],

            responsive: {
                0: {
                    items: 1,

                    stagePadding: 0,
                },

                567: {
                    items: 1,

                    stagePadding: 0,
                },

                767: {
                    items: 1,

                    stagePadding: 75,
                },

                992: {
                    items: 2,

                    stagePadding: 75,
                },

                1200: {
                    items: 2,

                    stagePadding: 100,
                },
            },
        });
    });

    $("#les_comodites_slider").owlCarousel({
        items: 6,

        loop: false,

        dots: true,

        nav: false,

        margin: 10,

        responsiveClass: true,

        responsive: {
            0: { items: 2 },

            567: { items: 3 },

            992: { items: 4 },

            1200: {
                items: 6,
            },
        },
    });
    $("#nav_to_other_programes_slider").owlCarousel({
        items: 2,
        loop: true,
        dots: false,
        nav: false,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        responsiveClass: true,
    });
    $("#projet_action_tpvv_row").owlCarousel({
        items: 5,
        loop: false,
        dots: false,
        nav: false,
        margin: 10,
        responsiveClass: true,
        responsive: {
            0: { items: 2 },
            567: { items: 3 },
            992: { items: 4 },
            1200: { items: 5 },
        },
    });

    const comoditer_actu_page_projet_slider_nextIcon = '<img src="/assets/images/comoditer_actu_page_projet_slider_nextIcon.png">';

    $("#comoditer_actu_page_projet_slider").owlCarousel({
        items: 3,

        loop: false,

        dots: false,

        nav: true,

        stagePadding: 100,

        margin: 20,

        responsiveClass: true,

        autoplayTimeout: 8000,

        autoplayHoverPause: true,

        navText: [comoditer_actu_page_projet_slider_nextIcon, comoditer_actu_page_projet_slider_nextIcon],

        responsive: {
            0: {
                items: 1,

                stagePadding: 20,
            },

            567: {
                items: 2,

                stagePadding: 50,
            },

            992: {
                items: 3,
            },
        },
    });

    $("#apropos_page_slider").owlCarousel({
        items: 1,

        loop: true,

        dots: false,

        nav: true,

        stagePadding: 10,

        margin: 20,

        responsiveClass: true,

        autoplayTimeout: 8000,

        autoplayHoverPause: true,

        navText: [comoditer_actu_page_projet_slider_nextIcon, comoditer_actu_page_projet_slider_nextIcon],

        responsive: {
            0: {
                items: 1,

                stagePadding: 20,
            },

            567: {
                items: 1,

                stagePadding: 50,
            },

            992: {
                items: 1,
            },
        },
    });

    $("#upload_file").on("click", function () {
        $(".fichier_hidden").trigger("click");
    });

    $(".fichier_hidden").on("change", function () {
        var fileName = $(this)[0].files[0].name;

        $(".fichier").val(fileName);
    });

    $("#upload_file_02").on("click", function () {
        $(".fichier_hidden_02").trigger("click");
    });

    $(".fichier_hidden_02").on("change", function () {
        var fileName = $(this)[0].files[0].name;

        $(".fichier_02").val(fileName);
    });

    $(".almaz_developpeur_projet_images_slider").each(function () {
        $(this).magnificPopup({
            delegate: "img.img-fluid",

            type: "image",

            mainClass: "mfp-with-zoom mfp-img-mobile",

            image: {
                verticalFit: true,
            },

            gallery: {
                enabled: true,
            },

            callbacks: {
                open: function () {
                    this.wrap.on("click.pinhandler", ".pin-it", function (e) {});
                },

                beforeClose: function () {},
            },
        });
    });

    $(".zoom_gallery").magnificPopup({
        delegate: "a",

        type: "image",

        gallery: {
            enabled: true,

            navigateByImgClick: true,

            preload: [0, 1], // Will preload 0 - before current, and 1 after the current image
        },

        callbacks: {
            elementParse: function (item) {
                console.log(item.el[0].className);

                if (item.el[0].className == "video") {
                    (item.type = "iframe"),
                        (item.iframe = {
                            patterns: {
                                youtube: {
                                    index: "youtube.com/",

                                    id: "v=",

                                    src: "//www.youtube.com/embed/%id%?autoplay=1",
                                },

                                vimeo: {
                                    index: "vimeo.com/",

                                    id: "/",

                                    src: "//player.vimeo.com/video/%id%?autoplay=1",
                                },

                                gmaps: {
                                    index: "//maps.google.",

                                    src: "%id%&output=embed",
                                },
                            },
                        });
                } else {
                    (item.type = "image"),
                        (item.tLoading = "Loading image #%curr%..."),
                        (item.mainClass = "mfp-img-mobile"),
                        (item.image = {
                            tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
                        });
                }
            },
        },
    });

    $(".zoom_gallery_img").magnificPopup({
        delegate: "img",

        type: "image",

        mainClass: "mfp-with-zoom mfp-img-mobile",

        image: {
            verticalFit: true,
        },

        gallery: {
            enabled: true,
        },

        callbacks: {
            open: function () {
                this.wrap.on("click.pinhandler", ".pin-it", function (e) {});
            },

            beforeClose: function () {},
        },
    });

    $(".zoom_gallery_slider_imgs").on("click", function (event) {
        event.preventDefault();

        var gallery = $(this).attr("href");

        $(gallery)
            .magnificPopup({
                delegate: "a",

                type: "image",

                gallery: {
                    enabled: true,
                },
            })

            .magnificPopup("open");
    });

    var wow = new WOW({
        boxClass: "wow",

        animateClass: "animated",

        offset: 0,

        mobile: true,

        live: true,

        callback: function (box) {},

        scrollContainer: null,
    });

    new WOW().init();

    // Button AccÃ¨s rapide

    $("#acces_rapide_button").on("mouseenter", function (e) {
        e.preventDefault();

        e.stopPropagation();

        $(".acces_rapide").toggleClass("animate");
    });

    $("#acces_rapide_button").on("click", function (e) {
        e.preventDefault();

        e.stopPropagation();

        $(".acces_rapide").toggleClass("animate");
    });

    $(".acces_rapide").on("click", function (e) {
        e.stopPropagation();
    });

    $("body").on("click", function (e) {
        e.stopPropagation();

        $(".acces_rapide").removeClass("animate");
    });
    ///************************************** */
    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + exdays * 86400000 * 356);
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";

        console.log(expires);
    }

    function getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(";");
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == " ") {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    $("#jaccepte_conditions").click(function () {
        setCookie("lesitedejavu", "Yes", 1);
        $("#popup_leprojet_cookies").hide();
    });

    $(window).on("load", function () {
        if (getCookie("lesitedejavu") == "") {
            $("#popup_leprojet_cookies").show();
        }
    });
});

// var a = 0;

// $(window).scroll(function () {

//   $(".count").each(function () {

//     var $this = $(this),

//       countTo = $this.attr("data-count");

//     $({

//       countNum: $this.text(),

//     }).animate(

//       {

//         countNum: countTo,

//       },

//       {

//         duration: 3000,

//         easing: "swing",

//         step: function () {

//           $this.text(Math.ceil(this.countNum).toLocaleString("fr"));

//         },

//         complete: function () {

//           $this.text(Math.ceil(this.countNum).toLocaleString("fr"));

//         },

//       }

//     );

//   });

//   a = 1;

// });

var a = 0;

$(window).scroll(function () {
    var oTop = $(".en_chiffres_item_text").offset().top - window.innerHeight;

    if (a == 0 && $(window).scrollTop() > oTop) {
        $(".count").each(function () {
            var $this = $(this),
                countTo = $this.attr("data-count");

            $({
                countNum: $this.text(),
            }).animate(
                {
                    countNum: countTo,
                },

                {
                    duration: 3000,

                    easing: "swing",

                    step: function () {
                        $this.text(Math.ceil(this.countNum).toLocaleString("fr"));
                    },

                    complete: function () {
                        $this.text(Math.ceil(this.countNum).toLocaleString("fr"));

                        //alert('finished');
                    },
                }
            );
        });

        a = 1;
    }
});

// Counter
