import $ from "jquery";
global.$ = $;
require("jquery-validation");


$.validator.addMethod("email", function(value, element) {
    return this.optional(element) || /^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+\.[a-zA-Z.]{2,5}$/i.test(value);
}, "Adresse e-mail non valide: Veuillez entrer une adresse e-mail valide.");
$.validator.addMethod("telephone", function(value, element) {
    return this.optional(element) || /^[+]{0,1}[0-9]{10,14}$/i.test(value);
}, "Format invalide. (Exemple: 0611111111)");
//$("#form_telephone").mask("06 00 00 00 00");

$("#form_lead_register").validate({
    rules: {
        'lead[nom]': "required",
        'lead[prenom]': "required",
        'lead[mail]': {
            required: true,
            email: true
        },
        'lead[telephone]': {
            required: true,
            telephone: true
        },
        'lead[ville]': "required",
        'lead[privace]': "required"
    },
    messages: {
        'lead[nom]': "nom obligatoir",
        'lead[prenom]': "prenom obligatoir",
        'lead[mail]': {
            required: "email obligatoir",
            email: "form de votre emial invalide"
        },
        'lead[telephone]': {
            required: "telephone obligatoir",
            telephone: "format de telephone invalide"
        },
        'lead[ville]': "ville obligatoir",
        'lead[privace]': "merci de accept les contion privace"
    }
});